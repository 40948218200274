import React from "react";
import PropTypes from "prop-types";
import Apostrophe from "root/components/SvgColor/Apostrophe";

import "./index.css";

const Blockquote = ({ children }) => {
  return (
    <div className="blockquote">
      <div className="apostrophe">
        <Apostrophe color="yellow" />
      </div>
      <div className="quote">{children}</div>
    </div>
  );
};

Blockquote.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Blockquote;
