/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import Img from "gatsby-image";

import withTrans from "root/i18n/withTrans";

import Typography from "root/components/Typography";
import Button from "root/components/Button";

import { useTranslation } from "react-i18next";
import styles from "./index.module.css";

function BlogPostPreview({
  selectCategory,
  postData: {
    title,
    description,
    author,
    date,
    path,
    featuredImage,
    category,
    alt,
  },
}) {
  const { t } = useTranslation();
  const name = author?.childAuthorsJson.name ?? "";
  const imageAlt = alt ?? "image preview";
  const categoryIndex = t("blog.blogPostList.categories").indexOf(category);

  // This readMore might be later replaced by the translations file content
  const readMore = t("blog.blogPostList.readMore");
  const dateCapitalized = date.charAt(0).toUpperCase() + date.slice(1);

  return (
    <div className={styles.root}>
      <Link to={`/blog/articles/${path}`}>
        <Img
          className={styles.image}
          alt={imageAlt}
          fluid={featuredImage ? featuredImage.image.fluid : ""}
          objectFit="cover"
        />
      </Link>
      <div className={styles.post_details}>
        <Typography color="black" variant="smallBody">
          <span
            className={styles.post_category}
            onClick={() => selectCategory(categoryIndex)}
          >
            {category}
          </span>
          {`| ${name} | ${dateCapitalized}`}
        </Typography>
      </div>
      <div className={styles.post_title}>
        <Link to={`/blog/articles/${path}`}>
          <Typography color="black" variant="body" weight="medium">
            {title}
          </Typography>
        </Link>
      </div>
      <div className={styles.post_description}>
        <Typography color="black" variant="body">
          {description}
        </Typography>
      </div>
      <div className={styles.read_more}>
        <Link to={`/blog/articles/${path}`}>
          <Button color="yellow" size="small">
            <Typography variant="body">{readMore}</Typography>
          </Button>
        </Link>
      </div>
    </div>
  );
}

BlogPostPreview.propTypes = {
  postData: PropTypes.shape().isRequired,
  selectCategory: PropTypes.func.isRequired,
};

export default withTrans(BlogPostPreview);
