import React from "react";
import PropTypes from "prop-types";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { MDXProvider } from "@mdx-js/react";
import Helmet from "react-helmet";
import Img from "gatsby-image";
import { useStaticQuery, Link, graphql } from "gatsby";
import Layout from "root/components/Layout";
import Typography from "root/components/Typography";
import Navbar from "root/components/Navbar";
import Blockquote from "root/components/Blockquote";
import favicon from "root/assets/sioslifeFavicon.svg";
import yellowCurve from "root/assets/blog yellow-curve.svg";
import Grid from "root/components/Grid";
import BlogPostPreview from "root/components/BlogPostPreview";
import Apoios from "root/components/Apoios";
import Footer from "root/components/Footer";

import "./index.css";
import { useTranslation } from "react-i18next";

function BlogPost({ pageContext }) {
  const { t, i18n } = useTranslation();
  const { frontmatter, body } = pageContext.blogPost;
  const author = frontmatter.author?.childAuthorsJson ?? "";
  const altText = frontmatter.alt ?? "";
  const keyword = frontmatter.keyword ?? "";
  let currentLanguage;

  switch (i18n.language) {
    case "pt-PT":
      currentLanguage = "pt";
      break;
    case "es-ES":
      currentLanguage = "es";
      break;
    default:
      currentLanguage = "pt";
      break;
  }

  const data = useStaticQuery(graphql`
    query {
      allMdx(sort: { order: DESC, fields: frontmatter___date }) {
        edges {
          node {
            frontmatter {
              path
              author {
                childAuthorsJson {
                  name
                }
              }
              date(formatString: "MMM DD, YYYY", locale: "Pt")
              title
              description
              category
              language
              featuredImage {
                image: childImageSharp {
                  fluid(maxWidth: 1440, quality: 75) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const { edges } = data.allMdx;
  const postsFiltered = edges.filter(
    ({
      node: {
        frontmatter: { language },
      },
    }) => {
      return language.toLowerCase() === currentLanguage;
    },
  );
  const threeMostRecentPosts = postsFiltered.slice(0, 3);

  const renderPreviews = () => {
    return threeMostRecentPosts.map(({ node }) => (
      <BlogPostPreview postData={node.frontmatter} key={frontmatter.path} />
    ));
  };

  // Passing custom components to style what is rendered by MDXRenderer
  const bodyP = props => <Typography color="black" {...props} />;
  const bodyH1 = props => <Typography color="black" variant="h1" {...props} />;
  const bodyH2 = props => <Typography color="black" variant="h2" {...props} />;
  const bodyH3 = props => <Typography color="black" variant="h3" {...props} />;
  const bodyUl = props => (
    <li>
      <Typography color="black" {...props} />
    </li>
  );
  const bodyBlockquote = props => <Blockquote {...props} />;
  const bodyA = props => (
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    <a {...props} rel="noopener noreferrer nofollow" target="_blank" />
  );

  // These components are passed to MDXRenderer through MDXProvider
  const MDXComponents = {
    // eslint-disable-next-line id-length
    p: bodyP,
    // eslint-disable-next-line id-length
    a: bodyA,
    h1: bodyH1,
    h2: bodyH2,
    h3: bodyH3,
    li: bodyUl,
    blockquote: bodyBlockquote,
  };

  const meta = (
    <Helmet>
      <title>{frontmatter.title}</title>
      <link rel="icon" type="image/svg" href={favicon} />
      <meta name="author" property="og:author" content={author.name} />
      <meta name="keywords" content={keyword} />
      <meta
        name="description"
        property="og:description"
        content={frontmatter.description}
      />
      <meta
        name="image"
        property="og:image"
        content={`https://sioslife.com${
          frontmatter.featuredImage
            ? frontmatter.featuredImage.image.fluid.src
            : ""
        }`}
      />
      <meta
        name="image"
        property="og:image:secure_url"
        content={`https://sioslife.com${
          frontmatter.featuredImage
            ? frontmatter.featuredImage.image.fluid.src
            : ""
        }`}
      />
      <meta name="title" property="og:title" content={frontmatter.title} />
      <meta name="type" property="og:type" content="article" />
      <meta
        name="url"
        property="og:url"
        content={`https://sioslife.com${frontmatter.path}`}
      />
      <meta
        name="twitter:card"
        property="twitter:card"
        content="summary_large_image"
      />
      <meta
        name="twitter:creator"
        property="twitter:creator"
        content={author.twitter}
      />
      <meta
        name="twitter:description"
        property="twitter:description"
        content={frontmatter.description}
      />
      <meta
        name="twitter:image"
        property="twitter:image"
        content={`https://sioslife-site.netlify.com${
          frontmatter.featuredImage
            ? frontmatter.featuredImage.image.fluid.src
            : ""
        } `}
      />
      <meta
        name="twitter:image:alt"
        property="twitter:image:alt"
        content={`${frontmatter.title} preview`}
      />
      <meta
        name="twitter:site"
        property="twitter:site"
        content="@AuroraDigitalH1"
      />
      <meta
        name="twitter:title"
        property="twitter:title"
        content={frontmatter.title}
      />
      <meta
        name="twitter:url"
        property="twitter:url"
        content={`https://sioslife-site.netlify.com${frontmatter.path}`}
      />
    </Helmet>
  );

  const capitalizeFirstLetter = string => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <div>
      {meta}
      <Layout>
        <Navbar
          currentPage="Blog Post"
          color="blue"
          blueLogin="true"
          blogPostTranslation={frontmatter.translation}
        />
        <div className="root">
          <main className="content">
            <article className="article">
              <div className="article__header">
                <div className="article__header__grid">
                  <Grid columns={54} lines={18} opacity={0.9} />
                </div>
                <Img
                  className="article__header__banner"
                  alt={altText}
                  fluid={
                    frontmatter.featuredImage
                      ? frontmatter.featuredImage.image.fluid
                      : ""
                  }
                  objectFit="cover"
                />
                <img
                  src={yellowCurve}
                  alt="decorative yellow curve"
                  className="article__header__curve"
                />
              </div>
              <div className="article__body">
                <div className="author">
                  <Typography variant="body" color="black">
                    {`${frontmatter.category} | ${
                      author.name
                    } | ${capitalizeFirstLetter(frontmatter.date)}`}
                  </Typography>
                </div>
                <div className="article__body__title">
                  <Typography weight="medium" color="blue" variant="h2">
                    {frontmatter.title}
                  </Typography>
                </div>
                <div className="article__body__content">
                  <MDXProvider components={MDXComponents}>
                    <MDXRenderer>{body}</MDXRenderer>
                  </MDXProvider>
                </div>
              </div>
            </article>
            <nav className="nav">
              <div className="nav__title">
                <Typography color="blue">Sioslife</Typography>
              </div>
              <Typography color="black">
                {t("blog.blogPost.description")}
              </Typography>
              <div className="nav__title nav__title--category">
                <Typography color="blue">
                  {t("blog.blogPost.categoryTitle")}
                </Typography>
              </div>
              <div className="nav__category-list">
                {t("blog.blogPostList.categories").map((category, index) => {
                  return (
                    <Link
                      to={`/${currentLanguage}/blog`}
                      state={{ category: index }}
                    >
                      <div className="nav__category-list__category">
                        <Typography color="black">
                          {t("blog.blogPostList.categories")[index]}
                        </Typography>
                      </div>
                    </Link>
                  );
                })}
              </div>
            </nav>
          </main>
          <div className="latest-posts">
            <div className="latest-posts__content">
              <div className="latest-posts__content__title">
                <Typography color="blue" variant="h3">
                  {t("blog.blogPost.latestPosts")}
                </Typography>
              </div>
              <div className="latest-posts__content__posts">
                {renderPreviews()}
              </div>
            </div>
          </div>
        </div>
        <Footer />
        <Apoios />
      </Layout>
    </div>
  );
}

BlogPost.propTypes = {
  pageContext: PropTypes.shape({
    blogPost: PropTypes.shape({
      frontmatter: PropTypes.shape({
        path: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        category: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
        featuredImage: PropTypes.shape().isRequired,
        author: PropTypes.shape({
          childAuthorsJson: PropTypes.shape({
            name: PropTypes.string.isRequired,
            twitter: PropTypes.string,
            twitterid: PropTypes.string,
          }).isRequired,
        }).isRequired,
        alt: PropTypes.string,
        keyword: PropTypes.string,
        translation: PropTypes.string,
      }).isRequired,
      body: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default BlogPost;
